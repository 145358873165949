.join-main{
    background-color: #2d8dff;
    width: 100%;
    height: 100vh;
}


.join-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
 
}

.join-logo{
    width: 80%;
    max-width: 800px;
    display: flex;
justify-content: center;
align-items: center;
position: relative;
}

.logo{
    width: 20%;
    filter: drop-shadow(2px 2px 5px #2222225c);
    z-index: 2;
}

.logo-text{
    width: 75%;
    margin-left: 5%;
    filter: drop-shadow(5px 5px 3px #2222225c);
    z-index: 2;
}

.email-content{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.email-label{
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
}

.email-input{
    font-size: 1.5rem;
    padding: 5px;
    border: none;
    outline: none;
}

.email-button{
    font-size: 1.5rem;
    padding: 5px;
    border: none;
    outline: none;
    color: white;
    background-color: #92d051;
    font-weight: bold;
}

.social-media-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  
margin-top: 2vh;
}

.social-media-section{
    margin-top: 10vh;
    width: 35%;
    max-width: 200px;
}

.social-media-icon{
    width: 20%;
    cursor: pointer;
}

.social-media-icon-big{
    width: 100%;
    cursor: pointer;
}

.gw-text-xs{
    color: white !important;
}

.gw-flex{
    background-color: transparent;
}

.gw-bg-transparent{
    background-color: white !important;
    font-size: 1.5rem !important;
    border: 1px solid gray !important;
    outline: none !important;
    z-index: 2;
}

#primaryCTA{
    font-size: 1.5rem !important;
    font-weight: bold !important;
}

#gw_success{
    color: white !important;
}

.icon{
    width: 100%;
}

.white-triangle{
    width: 100%;
    position: absolute;
    height: 200vh;
    background-color: white;
    z-index: 2;
    margin-left: -106%;
    clip-path: polygon(0 0,100% 0,50% 100%,0 100%);
}


#getWaitlistContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-instructions{
    width: 97%;
    text-align: center;
    color: white;
    margin-bottom: 15px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    position: relative;
    z-index: 3;
    margin: 0 auto;
    backdrop-filter: blur(5px);
    font-family: sans-serif;
    font-size: 0.9rem;
    line-height: 1.5;
}

@media (min-width:576px) {
    .email-instructions{
        width: 100%;
font-size: 20px;
margin-bottom: 20px;
    }
}

.social-media-heading{
    font-size: 18px;
    text-align: center;
    color: white;
    font-weight: bold;
}




