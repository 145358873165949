.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #2d8dff;
   padding-bottom: 20px;
   z-index: 0 !important;
   position: relative;
}

.footer-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 18px;
    text-align: center;
    position: relative;

    a{
        width: 30%;
        cursor: pointer;
    }
}


.footer-image{
    width: 100%;
    max-width: 200px;
    margin-top: 10px;
}