@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
  font-family: "Roboto", sans-serif !important;
  background-color: #2d8dff;
}

.App{
  overflow: hidden;
}


.white-area{
  width: 20%;
  height: 100%;
  background-color: white;
  position: fixed;
  left: 0px;
  top: 0px;
}

@media (max-width:1600px) {
  .white-area{
display: none;
  }
}
